<template>
  <div class="home">
    <Header :msg="$t('message')" />
  </div>
</template>

<script>
// @ is an alias to /src
//import Header from '@/components/Header.vue';

export default {
  name: 'HomePage',
  components: {
    //  Header,
  },
};
</script>
